import React, { useState, useRef, useCallback } from 'react';
import './index.css';

const aspectRatios = {
  '1:1': { width: 1, height: 1 },
  '4:3': { width: 4, height: 3 },
  '3:2': { width: 3, height: 2 },
  '16:9': { width: 16, height: 9 },
  '2:1': { width: 2, height: 1 },
  '2:3': { width: 2, height: 3 },
  '4:5': { width: 4, height: 5 },
  '9:16': { width: 9, height: 16 },
};

const PhotoFramingApp = () => {
  const [photos, setPhotos] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState('1:1');
  const [padding, setPadding] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef(null);

  const handlePhotoUpload = (event) => {
    const files = Array.from(event.target.files);
    const newPhotos = files.map(file => ({
      original: URL.createObjectURL(file),
      framed: null,
      name: file.name
    }));
    setPhotos([...photos, ...newPhotos]);
  };

  const framePhoto = useCallback((photo) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const ratio = aspectRatios[selectedFormat];
        
        const frameWidth = Math.max(img.width, img.height * (ratio.width / ratio.height));
        const frameHeight = Math.max(img.height, img.width * (ratio.height / ratio.width));
        
        const paddingFactor = 1 + (padding / 10);
        canvas.width = frameWidth * paddingFactor;
        canvas.height = frameHeight * paddingFactor;
        
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        const x = (canvas.width - img.width) / 2;
        const y = (canvas.height - img.height) / 2;
        ctx.drawImage(img, x, y, img.width, img.height);
        
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.9); // Slightly reduced quality for better performance
      };
      img.src = photo.original;
    });
  }, [selectedFormat, padding]);

  const handleFramePhotos = async () => {
    setIsProcessing(true);
    const framedPhotos = [];
    for (const photo of photos) {
      const framedBlob = await framePhoto(photo);
      framedPhotos.push({
        ...photo,
        framed: framedBlob,
        framedUrl: URL.createObjectURL(framedBlob)
      });
    }
    setPhotos(framedPhotos);
    setIsProcessing(false);
  };

  const handleDownload = (photo) => {
    if (photo.framed) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(photo.framed);
      link.download = `framed_${photo.name}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const handleBulkDownload = async () => {
    const JSZip = (await import('jszip')).default;
    const zip = new JSZip();
    
    for (const photo of photos) {
      if (photo.framed) {
        zip.file(`framed_${photo.name}`, photo.framed);
      }
    }
    
    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = "framed_photos.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Photo Framing App</h1>
      
      <div className="mb-6">
        <button 
          onClick={() => fileInputRef.current.click()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Upload Photos
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handlePhotoUpload}
          multiple
          accept="image/*"
          className="hidden"
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="format">
          Select Format
        </label>
        <select 
          id="format"
          value={selectedFormat} 
          onChange={(e) => setSelectedFormat(e.target.value)}
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          {Object.keys(aspectRatios).map(ratio => (
            <option key={ratio} value={ratio}>{ratio}</option>
          ))}
        </select>
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="padding">
          Frame Padding: {padding}
        </label>
        <input 
          id="padding"
          type="range" 
          min="0" 
          max="10" 
          value={padding} 
          onChange={(e) => setPadding(parseInt(e.target.value))} 
          className="w-full"
        />
      </div>

      <div className="mb-6">
        <button 
          onClick={handleFramePhotos} 
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
          disabled={isProcessing || photos.length === 0}
        >
          {isProcessing ? 'Processing...' : 'Frame Photos'}
        </button>
        <button 
          onClick={handleBulkDownload} 
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          disabled={!photos.some(p => p.framed)}
        >
          Download All
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.map((photo, index) => (
          <div key={index} className="border rounded-lg p-4 flex flex-col items-center">
            <img
              src={photo.framedUrl || photo.original}
              alt={`Photo ${index + 1}`}
              className="w-full h-48 object-contain mb-4"
            />
            <button 
              onClick={() => handleDownload(photo)} 
              disabled={!photo.framed}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm"
            >
              Download
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoFramingApp;
